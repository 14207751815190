import { render, staticRenderFns } from "./ReportProblem.vue?vue&type=template&id=97b95774&scoped=true&"
import script from "./ReportProblem.vue?vue&type=script&lang=js&"
export * from "./ReportProblem.vue?vue&type=script&lang=js&"
import style0 from "./ReportProblem.vue?vue&type=style&index=0&id=97b95774&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97b95774",
  null
  
)

export default component.exports