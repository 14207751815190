<template>
  <div class="report-wrapper">
    <div class="report-content">
      <div style="marginBottom:20px">
        <VueText @click="downloadGuide" class="report-title" sizeLevel="6" weightLevel="1">
          Daha önce karşılaşılan sorunlar ve çözümleri ile ilgili detaylı bilgilendirme kılavuzuna
          <span style="color:blue;">buraya</span>
          tıklayarak ulaşabilirsiniz.</VueText
        >
      </div>
      <div style="marginBottom:40px">
        <VueText class="report-title" sizeLevel="6" weightLevel="3"
          >Mağaza Numarası(karekod etiketinde bulunan 0 veya D ile başlayan kod)*</VueText
        >
        <div class="question-text-area">
          <textarea
            v-model="storeText"
            id="storeId"
            placeholder="..."
            class="option"
            rows="1"
            minlength="9"
          />
        </div>

        <VueText
          v-if="isWarningMessage"
          style="marginTop:5px"
          weightLevel="2"
          sizeLevel="4"
          color="red-10"
        >
          Min 9 karakter giriniz.
        </VueText>
      </div>
      <div style="marginBottom:40px">
        <VueText class="report-title" sizeLevel="6" weightLevel="3"
          >Yazarak yaşadığınız sorunu belirtin*</VueText
        >
        <VueText style="margin-top: 10px;" sizeLevel="3" weightLevel="1"
          >Barkodlu etikette bulunan en üstteki mağaza numarasını (0 veya D ile başlayan) yazarak
          yaşadığınız sorunu belirtin.
        </VueText>

        <div class="question-text-area">
          <textarea
            v-model="openText"
            placeholder="Sorununuzu giriniz..."
            class="option"
            rows="3"
            id="questionId"
            minlength="9"
          ></textarea>
        </div>

        <div style="alignSelf: flex-end;">
          <VueText
            style="fontStyle: italic;marginBottom:5px;marginRight:25px"
            weightLevel="4"
            sizeLevel="4"
          >
            *Zorunlu alan
          </VueText>
        </div>
      </div>
      <div>
        <VueText sizeLevel="6" weightLevel="3" style="padding-bottom: 10px;" color="grey-40"
          >Dilerseniz size daha hızlı ulaşabilmemiz için bizimle iletişim numaranızı aşağıda
          paylaşabilirsiniz. *(Opsiyonel)</VueText
        >
        <VueText
          sizeLevel="4"
          weightLevel="1"
          id="reportProblemPrivacy"
          style="padding-bottom: 20px;"
          v-html="reportProblemPrivacy"
        />
        <VueInput
          name="phoneNumber"
          mask="5#########"
          id="phoneNumber"
          type="tel"
          validatorName="Telefon Numarası"
          v-model="phoneNumber"
          :messageOnTop="true"
          validationRules="numeric|max:10|min:10"
        />
      </div>
    </div>

    <VueText
      style="marginBottom:15px;marginLeft:20px"
      weightLevel="2"
      sizeLevel="4"
      color="red-10"
      >{{ errorMessage }}</VueText
    >

    <BrandButton
      style="marginBottom:10%"
      :disabled="isBrandButtonDisable"
      :size="sizes.xxLarge"
      type="submit"
      @click="report"
    >
      GÖNDER</BrandButton
    >
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import StorageProps from '@/mixins/storageProps.js';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import ImageRecognition from '@/services/Api/imageRecognition.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'ReportProblem',
  components: { VueText, BrandButton, VueInput },
  data() {
    return {
      openText: '',
      storeText: '',
      phoneNumber: '',
      isBrandButtonDisable: true,
      isWarningMessage: false,
      errorMessage: '',
      reportProblemPrivacy: '',
    };
  },
  mixins: [StorageProps],
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
  },
  watch: {
    openText: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.validate('questionId');
        }
      },
    },
    storeText: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.isWarningMessage = this.storeText.length < 9 && this.storeText != '';
          this.validate('storeId');
        }
      },
    },
  },
  methods: {
    validate(id) {
      let inputField = document.getElementById(`${id}`).value;
      inputField = inputField.replace(/^\s+/, '').replace(/\s+$/, '');
      if (id == 'questionId') {
        this.isBrandButtonDisable =
          inputField == '' || !this.storeText || this.storeText.length < 9;
      } else if (id == 'storeId') {
        this.isBrandButtonDisable = inputField == '' || !this.openText || this.storeText.length < 9;
      }
    },

    report() {
      ImageRecognition.reportProblem({
        reportingTypeId: 5,
        openText: this.openText,
        customerCode: this.storeText,
        phoneNumber: this.phoneNumber,
      })
        .then(res => {
          this.errorMessage = '';
          if (res.status === 200) {
            const modalConfig = {
              isModalOpened: true,
              modalText: `<div style="font-size: 20px; margin-top: 12px; margin-bottom:30px;">Sorun Bildirildi.</div>`,
              firstButtonText: 'TAMAM',
              modalConfirmationText: '',
              firstButtonFn: () => {
                this.$router.push({ name: 'image-recognition-home' });
              },
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          }
        })
        .catch(err => {
          if (err.response.data.Error && err.response.data.Error.Code === 500) {
            this.errorMessage = 'Geçersiz mağaza numarası!';
          }
        });
    },

    downloadGuide() {
      StaticContent.getStaticContent('IRSorunVeCevaplar').then(res => {
        let {
          Data: { content },
        } = res.data;

        const baseUrl = process.env.VUE_APP_CDN_BASE_URL;
        window.open(`${baseUrl}${content}`, '_blank');
      });
    },
    privacyPolicy() {
      StaticContent.getStaticContent('ReportProblemPrivacyText').then(res => {
        let {
          Data: { content },
        } = res.data;
        this.reportProblemPrivacy = content.replace('data-policy', 'privacy-policy');
      });
    },
  },
  mounted() {
    this.privacyPolicy();
  },
};
</script>

<style lang="scss" scoped>
.report {
  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;
    overflow-y: auto;
  }
  &-content {
    margin-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
  }
  &-title {
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.question-text-area {
  textarea {
    &.option {
      border: 0.5px solid palette-color('grey-30');
      border-radius: 4px;
      width: 100%;
      padding: 15px;
      margin-top: 5px;
      color: #23303d;
      font-family: 'Roboto', sans-serif;
    }
  }
}
</style>
